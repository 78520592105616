<template>
  <div class="user-actions">
    <div class="user-avatar" @click="$isMobile ? (open = true) : ''">
      <img src="@/assets/images/user-avatar-wrapper.svg" alt="User" />
      <span class="user-name" v-if="user.fio">
        {{ user.fio[0] }}
      </span>
    </div>
    <div class="user-dropdown" v-if="!$isMobile || ($isMobile && open)">
      <div
        class="user-dropdown-content"
        :class="{ open: showContent }"
        v-click-outside="closeDropdown"
      >
        <div
          class="user-actions__close"
          @click="closeDropdown"
          v-if="$isMobile"
        >
          <img src="@/assets/icons/clear.svg" alt="Close" />
        </div>
        <div class="navbar-item navbar-item_name" v-if="user.fio">
          {{ user.fio }}
        </div>
        <div
          class="navbar-item"
          @click="closeDropdown"
          :class="{ active: $route.name == 'settings' }"
        >
          <base-link :size="16" :to="{ name: 'settings' }" :dropdown="true"
            >Настройки</base-link
          >
        </div>
        <div
          class="navbar-item"
          @click="logout(), $isMobile && closeDropdown()"
        >
          Выход
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import mobileDropdown from "@/mixins/mobileDropdown";

export default {
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    logout() {
      localStorage.removeItem("user");
      this.$store.dispatch("setUser", null);
      this.$router.push({ name: "login" });
    },
  },
  mixins: [mobileDropdown],
};
</script>

<style lang="scss" scoped>
.user {
  &-avatar {
    width: 43px;
    height: 43px;
    position: relative;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &-name {
    width: 20px;
    height: 20px;
    position: absolute;
    top: calc(50% - 8px);
    left: calc(50% - 8px);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $regular;
    font-size: 20px;
    line-height: 24px;
  }
  &-dropdown {
    width: 208px;
    height: auto;
    position: absolute;
    top: 39px;
    right: 0;
    padding-top: 13px;
    visibility: hidden;
    transform-origin: top right;
    transform: scale(0);
    opacity: 0;
    z-index: 99;
    transition: 0.2s ease;
    &-content {
      width: 100%;
      position: relative;
      padding: 17px 0 10px 0;
      border-radius: 5px;
      background: $white;
      box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.1);
      &:before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        top: -5px;
        right: 11px;
        box-sizing: border-box;
        border: 8px solid black;
        border-color: transparent transparent $white $white;
        transform: rotate(135deg);
        box-shadow: 0 1px 4px 0 rgba($black, 0.1);
        z-index: 0;
      }
      &:after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: $white;
        border-radius: 5px;
        z-index: 2;
      }
    }
    .navbar-item {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-family: $regular;
      font-size: 16px;
      padding: 9px 12px;
      transition: 0.2s ease;
      cursor: pointer;
      z-index: 3;
      &:hover,
      &.active {
        background: $grayHover;
      }
      &_name {
        font-family: $bold;
        padding: 2px 12px;
        line-height: 18px;
        margin-bottom: 7px;
        &:hover {
          background: transparent;
          opacity: 1;
        }
      }
    }
  }

  &-actions {
    position: relative;
    &:hover {
      .user-dropdown {
        transform: scale(1);
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
@media (max-width: 992px) {
  .user {
    &-actions {
      &__close {
        display: block;
        position: absolute;
        top: 12px;
        right: 12px;
        z-index: 9;
        opacity: 0.4;
        cursor: pointer;
      }
    }
    &-dropdown {
      width: 100%;
      height: 100%;
      position: fixed;
      bottom: 0;
      top: auto;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      transform: scale(1);
      opacity: 1;
      visibility: visible;
      padding: 0;
      border-radius: 0px;
      &:before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba($black, 0.5);
      }
      &-content {
        max-height: 168px;
        box-shadow: none;
        background: $white;
        border-radius: 0;
        padding-top: 24px;
        padding: 24px 0 20px 0;
        transform: translateY(100%);
        transition: 0.3s ease;
        &.open {
          transform: translateY(0);
        }
        &:before,
        &:after {
          display: none;
        }
      }
      .navbar-item {
        padding: 9.5px 16px;
        &_name {
          min-height: auto;
          font-size: 21px;
          line-height: 23px;
          padding: 0 100px 0 16px;
          margin-bottom: 8px;
        }
      }
    }
  }
}
</style>
