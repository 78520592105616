<template>
  <nav class="navbar">
    <div class="navbar-item">
      <base-link :to="{ name: 'offer' }">Коммерческое предложение</base-link>
    </div>
    <div class="navbar-item">
      <base-link :to="{ name: 'tech-docs' }">Техническое задание</base-link>
    </div>
    <div class="navbar-item">
      <base-link :to="{ name: 'certificate' }"
        >Регистрационное удостоверение</base-link
      >
    </div>
    <div class="navbar-item">
      <base-link
        :to="{
          name:
            user && user.access_group === 'manager' ? 'index' : 'statistics',
        }"
        >Статистика</base-link
      >
    </div>
    <div class="navbar-item navbar-requisites">
      <span
        class="navbar-requisites__title"
        :class="{
          active: $route.matched.some(
            (i) =>
              i.name == 'counterparty' ||
              i.name == 'endCustomer' ||
              i.name == 'companyRequisites'
          ),
        }"
        @click="$isMobile ? (open = true) : ''"
      >
        Реквизиты
      </span>
      <div
        class="navbar-requisites-dropdown"
        v-if="!$isMobile || ($isMobile && open)"
      >
        <div
          class="navbar-requisites-dropdown-content"
          :class="{ open: showContent }"
          v-click-outside="closeDropdown"
        >
          <div
            class="navbar-requisites-dropdown__close"
            @click="closeDropdown"
            v-if="$isMobile"
          >
            <img src="@/assets/icons/clear.svg" alt="Close" />
          </div>
          <div class="navbar-requisites-dropdown__title" v-if="$isMobile">
            Реквизиты
          </div>
          <div
            class="navbar-item"
            :class="{ active: $route.name == 'counterparty' }"
            @click="closeDropdown"
          >
            <base-link :to="{ name: 'counterparty' }" :dropdown="true"
              >Контрагент</base-link
            >
          </div>
          <div
            class="navbar-item"
            :class="{ active: $route.name == 'endCustomer' }"
            @click="closeDropdown"
          >
            <base-link :to="{ name: 'endCustomer' }" :dropdown="true"
              >Конечный заказчик</base-link
            >
          </div>
          <div
            class="navbar-item"
            :class="{ active: $route.name == 'companyRequisites' }"
            @click="closeDropdown"
          >
            <base-link :to="{ name: 'companyRequisites' }" :dropdown="true"
              >Реквизиты компаний</base-link
            >
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapState } from "vuex";
import mobileDropdown from "@/mixins/mobileDropdown";

export default {
  computed: {
    ...mapState(["user"]),
  },
  mixins: [mobileDropdown],
};
</script>

<style lang="scss" scoped>
.navbar {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 215px 0 39px;
  &-item {
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.2s ease;
  }
  &-requisites {
    position: relative;
    &__title {
      font-family: $regular;
      font-size: 13px;
      line-height: 15px;
      cursor: pointer;
      &:hover {
        opacity: 0.5;
      }
      &.active {
        font-family: $bold;
      }
    }
    &-dropdown {
      width: 161px;
      min-height: 101px;
      position: absolute;
      top: 40px;
      left: -8px;
      box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      background: $white;
      padding: 7px 0;
      opacity: 0;
      transform-origin: top left;
      transform: scale(0);
      overflow: hidden;
      z-index: 9;
      transition: 0.2s ease;
      .navbar-item {
        width: 100%;
        min-height: 29px;
        display: flex;
        align-items: center;
        padding-left: 10px;
        &.active,
        &:hover {
          background: $grayHover;
        }
      }
    }
    &:hover {
      .navbar-requisites-dropdown {
        transform: scale(1);
        opacity: 1;
      }
    }
  }
}
@media (max-width: 1280px) {
  .navbar {
    padding: 0 64px;
  }
}
@media (max-width: 992px) {
  .navbar {
    position: absolute;
    top: 56px;
    left: 0;
    height: 38px;
    padding: 0 16px;
    border-bottom: 1px solid $grayLighter;
    overflow: hidden;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    &-item {
      flex-shrink: 0;
      margin-left: 24px;
      &:first-child {
        margin: 0;
      }
    }
    &-requisites {
      &__title {
        font-size: 16px;
        line-height: 18px;
      }
      &-dropdown {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        background: transparent;
        padding: 0;
        border-radius: 0;
        transform: scale(1);
        opacity: 1;
        &:before {
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: rgba($black, 0.5);
        }
        &__close {
          position: absolute;
          top: 12px;
          right: 13px;
          opacity: 0.4;
        }
        &__title {
          font-family: $bold;
          font-size: 21px;
          line-height: 30px;
          font-weight: 700;
          color: $black;
          margin-bottom: 6px;
          padding-left: 16px;
        }
        &-content {
          width: 100%;
          height: 185px;
          position: relative;
          background: $white;
          padding: 20px 0 19px 0;
          z-index: 2;
          transform: translateY(100%);
          transition: 0.3s ease;
          &.open {
            transform: translateY(0);
          }
        }
      }
      .navbar-item {
        min-height: 37px;
        height: auto;
        margin: 0;
        padding: 0 16px;
      }
    }
  }
}
</style>
