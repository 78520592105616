import store from "../store/index";

export const mobileCheck = (width) => {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    ) ||
    width < 992
  ) {
    store.dispatch("mobileCheck", true);
  } else {
    store.dispatch("mobileCheck", false);
  }
};
