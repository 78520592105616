export const offer = [
  {
    path: "/offer",
    name: "offer",
    redirect: { name: "offers" },
    component: () =>
      import(
        /* webpackChunkName: "offers"*/ /* webpackPrefetch: true */ "@/pages/Offer.vue"
      ),
    children: [
      {
        path: "/offer/offers",
        name: "offers",
        component: () =>
          import(
            /* webpackChunkName: "offers"*/ /* webpackPrefetch: true */ "@/components/offer/OfferOffers.vue"
          ),
      },
      {
        path: "/offer/create",
        name: "offerCreate",
        component: () =>
          import(
            /* webpackChunkName: "offers"*/ /* webpackPrefetch: true */ "@/components/offer/OfferCreate.vue"
          ),
      },
    ],
  },
];
