import { createRouter, createWebHistory } from "vue-router";
import { customer } from "./customer";
import { offer } from "./offer";
import { requisites } from "./requisites";
import store from "../store/index";

const routes = [
  ...customer,
  ...offer,
  ...requisites,
  {
    path: "/:catchAll(.*)",
    component: () =>
      import(
        /* webpackChunkName: "not-found"*/ /* webpackPrefetch: true */ "@/pages/404.vue"
      ),
  },
];

const router = createRouter({
  mode: "history",
  history: createWebHistory("/"),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name !== "login" && !store.getters.user) {
    next({ name: "login" });
  }
  next();
});

export default router;
