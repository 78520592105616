export const requisites = [
  {
    path: "/counterparty",
    name: "counterparty",
    component: () =>
      import(
        /* webpackChunkName: "requisites"*/ /* webpackPrefetch: true */ "@/pages/Counterparty.vue"
      ),
  },
  {
    path: "/end-customer",
    name: "endCustomer",
    component: () =>
      import(
        /* webpackChunkName: "requisites"*/ /* webpackPrefetch: true */ "@/pages/EndCustomer.vue"
      ),
  },
  {
    path: "/company-requisites",
    name: "companyRequisites",
    component: () =>
      import(
        /* webpackChunkName: "requisites"*/ /* webpackPrefetch: true */ "@/pages/CompanyRequisites.vue"
      ),
  },
];
