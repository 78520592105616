const mobileDropdown = {
  data() {
    return {
      open: false,
      showContent: false,
    };
  },
  watch: {
    open() {
      if (this.open) {
        this.open = true;
        setTimeout(() => {
          this.showContent = true;
          document.body.style.overflow = "hidden";
        }, 50);
      }
    },
  },
  methods: {
    closeDropdown() {
      if (this.showContent) {
        this.showContent = false;
        setTimeout(() => {
          this.open = false;
          document.body.style.overflow = "";
        }, 300);
      }
    },
  },
};

export default mobileDropdown;
