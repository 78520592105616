<template>
  <div class="empty-layout">
    <!-- youter-view -->
    <router-view />
  </div>
</template>

<style lang="scss" scoped>
.empty-layout {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}
</style>
