<template>
  <div class="loader">
    <div class="spinner"></div>
  </div>
</template>

<style lang="scss" scoped>
.loader {
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba($white, 0.7);
  z-index: 999;
  .spinner {
    width: 43px;
    height: 43px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    border: 4px solid;
    border-color: $black $black transparent;
    transform-origin: center;
    animation: spinner 0.7s ease-in-out infinite;
  }
}
@keyframes spinner {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
