<template>
  <metainfo>
    <template #title="{ content }"
      >{{ content ? `${content} | Endo Stars` : `Endo Stars` }}
    </template>
  </metainfo>
  <!-- loader -->
  <Loader v-if="loading" />
  <component :is="layout"></component>
</template>

<script>
import { mapState } from "vuex";
import { useMeta } from "vue-meta";
import MainLayout from "@/components/layouts/MainLayout.vue";
import EmptyLayout from "@/components/layouts/EmptyLayout.vue";
import Loader from "@/components/Loader.vue";

export default {
  name: "App",
  components: {
    MainLayout,
    EmptyLayout,
    Loader,
  },
  computed: {
    ...mapState(["user"]),
    layout() {
      return this.user ? "main-layout" : "empty-layout";
    },
    loading() {
      return this.$store.getters.loading;
    },
  },
  mounted() {
    // logout after minutes of login
    const loggedDate = this.user?.loggedAt ?? new Date();
    const date = new Date();
    const diff = date?.getTime() - new Date(loggedDate)?.getTime();
    const minutes = Math.floor((diff / 60000) % 60);

    if (this.user && minutes >= 30) {
      this.logout();
    }
  },
  watch: {
    async user(val) {
      if (!val?.id) {
        await this.logout();
      }
    },
  },
  setup() {
    useMeta({
      title: "Главная страница, Коммерческое предложение",
      description: "asdad",
      htmlAttrs: { lang: "en", amp: true },
    });
  },
  methods: {
    async logout() {
      await localStorage.removeItem("user");
      await this.$store.dispatch("setUser", null);
      await this.$router.push({ name: "login" });
    },
  },
  async unmounted() {
    await this.logout();
  },
};
</script>

<style lang="scss">
#app {
  width: 100%;
  height: 100%;
  font-family: $regular;
}
</style>
