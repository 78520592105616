import axios from "axios";
import store from "../store/index";
import Config from "@/config.json";

let API = Config?.BASE_URL ?? "https://endo-sales.ru/api/v1";
const user = store.getters.user || null;

let headers = {
  "Content-Type": "application/json",
  Authorization: user ? `Token ${user.token}` : "",
};

export const axiosInstance = axios.create({
  API,
  headers,
});
