<template>
  <header>
    <div class="header">
      <!-- logo -->
      <div class="header-logo">
        <base-link :to="{ name: 'offers' }">
          <img
            src="@/assets/images/logo.svg"
            alt="Endo Stars"
            width="68"
            height="43"
          />
        </base-link>
      </div>
      <!-- navbar -->
      <Navbar />
      <!-- user profile and dropdown -->
      <div class="header-user">
        <header-user />
      </div>
    </div>
  </header>
</template>

<script>
import Navbar from "./components/Navbar.vue";
import HeaderUser from "./components/HeaderUser.vue";

export default {
  components: {
    Navbar,
    HeaderUser,
  },
};
</script>
