export const customer = [
  {
    path: "/",
    name: "index",
    redirect: "offer",
    component: () =>
      import(
        /* webpackChunkName: "index"*/ /* webpackPrefetch: true */ "@/pages/Index.vue"
      ),
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(
        /* webpackChunkName: "login"*/ /* webpackPrefetch: true */ "@/pages/Login.vue"
      ),
  },
  {
    path: "/tech-docs",
    name: "tech-docs",
    component: () =>
      import(
        /* webpackChunkName: "tech-docs"*/ /* webpackPrefetch: true */ "@/pages/TechDocs.vue"
      ),
  },
  {
    path: "/certificate",
    name: "certificate",
    component: () =>
      import(
        /* webpackChunkName: "certificate"*/ /* webpackPrefetch: true */ "@/pages/Certificate.vue"
      ),
  },
  {
    path: "/statistics",
    name: "statistics",
    component: () =>
      import(
        /* webpackChunkName: "statistics"*/ /* webpackPrefetch: true */ "@/pages/Statistics.vue"
      ),
  },
  {
    path: "/settings",
    name: "settings",
    component: () =>
      import(
        /* webpackChunkName: "settings"*/ /* webpackPrefetch: true */ "@/pages/Settings.vue"
      ),
  },
];
