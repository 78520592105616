<template>
  <div
    class="base-input"
    :class="{
      'base-input-error': message || error,
      'base-input-tableInput': tableInput,
      'base-input-row': label,
    }"
    ref="inputWrapper"
    v-click-outside="
      () => {
        suggestion = false;
      }
    "
  >
    <label :for="id" v-if="label" :style="labelStyle"> {{ label }}</label>
    <div class="input">
      <input
        ref="input"
        :class="{
          search,
          searchIcon,
          filled: modelValue,
          short,
          addProduct,
        }"
        :id="id"
        :name="id"
        :type="inputType"
        :required="required"
        :disabled="disabled"
        :readonly="readonly"
        :placeholder="placeholder"
        :value="modelValue"
        :minLength="minLength ? minLength : ''"
        :maxlength="maxLength ? maxLength : ''"
        @input="onInput($event.target)"
        @focus="$emit('focus'), (suggestion = true)"
        @blur="$emit('blur')"
        @click="$emit('click')"
        @keypress.enter="$emit('keypressEnter'), (suggestion = false)"
        @keypress="onKeypress($event)"
      />
      <span
        class="input-tableInput-value"
        v-if="tableInput && modelValue && symbol"
        @click="$refs.input.focus()"
      >
        <span>{{ modelValue }}</span> {{ symbol }}
      </span>
      <!-- search suggestion -->
      <div
        class="search-suggestion"
        :style="[suggestionDirection, { width: `${suggestionWidth}px` }]"
        v-if="search && suggestions.length && suggestion"
        ref="suggestion"
      >
        <button
          v-for="(s, i) in suggestions"
          :key="i"
          class="base-button"
          @click="
            $emit('update:modelValue', s.key),
              $emit('setSuggestion', s),
              (suggestion = false)
          "
        >
          {{ s.key }}
          <span v-if="s.inn">ИНН: {{ s.inn }}</span>
        </button>
      </div>
      <!-- error message -->
      <span class="base-input__msg" v-if="message">
        {{ message }}
      </span>
      <!-- search icon -->
      <div class="base-input-icon" :class="{ clear: modelValue }" v-if="search">
        <img
          src="@/assets/icons/search-icon.svg"
          width="29"
          height="26"
          alt="Search"
          v-if="!modelValue && searchIcon"
        />
        <img
          v-else-if="modelValue"
          src="@/assets/icons/clear.svg"
          alt="Clear"
          width="23"
          height="23"
          @click="$emit('clear')"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseInput",
  props: {
    type: {
      type: String,
      default: "text",
    },
    inputType: {
      type: String,
      default: "text",
    },
    id: {
      type: String,
    },
    label: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
    },
    message: {
      type: String,
      default: "",
    },
    error: {
      type: Boolean,
      default: false,
    },
    tableInput: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    modelValue: {
      type: [String, Number, null, undefined],
    },
    minLength: {
      type: Number,
    },
    maxLength: {
      type: Number,
    },
    minValue: {
      type: Number,
      default: 0,
    },
    maxValue: {
      type: Number,
      default: 100000000000000000000000000000,
    },
    disabled: {
      type: Boolean,
    },
    readonly: {
      type: Boolean,
    },
    search: {
      type: Boolean,
      default: false,
    },
    searchIcon: {
      type: Boolean,
      default: false,
    },
    suggestions: {
      type: Array,
    },
    suggestionWidth: {
      type: Number,
    },
    short: {
      type: Boolean,
    },
    labelStyle: {
      type: [Object, String],
    },
    addProduct: {
      type: Boolean,
    },
    symbol: {
      type: String,
    },
  },
  data() {
    return {
      suggestion: false,
      suggestionDirection: null,
    };
  },
  methods: {
    openSuggestion() {
      if (this.suggestions) this.suggestion = true;
      if (this.suggestion) {
        setTimeout(() => {
          let top = this.$refs.inputWrapper.getBoundingClientRect().top;
          if (window.innerHeight - top < 260)
            this.suggestionDirection = `top: auto; bottom: ${40}px`;
          else this.suggestionDirection = `top: ${40}px`;
        });
      }
    },
    onInput(target) {
      const value = target.value;

      if (this.type === "number") {
        let val = value?.replace(/[^0-9/.]/g, "");

        // replace value if that < 0
        if (+val < this.minValue) {
          target.value = 0;
          this.$emit("update:modelValue", this.minValue);
          return;
        }
        // replace value if that > 99
        if (+val > this.maxValue) {
          target.value = 99;
          this.$emit("update:modelValue", this.maxValue);
          return;
        }
        // slice value if that starts with 0
        if (val?.length > 1 && (val?.startsWith("0") || val?.startsWith("."))) {
          val = val.slice(1, val.length);
        }

        if (!val) {
          val = 0;
        }

        target.value = val;
        this.$emit("update:modelValue", val);
      } else {
        target.value = value;
        this.$emit("update:modelValue", value);
      }

      this.$emit("input");
    },
    onKeypress() {
      this.$emit("keypress");
      this.openSuggestion();
    },
  },
  emits: [
    "update:modelValue",
    "clear",
    "input",
    "focus",
    "blur",
    "click",
    "keypressEnter",
    "keypress",
    "setSuggestion",
  ],
};
</script>
