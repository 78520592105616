<template>
  <button
    class="base-button"
    :class="[
      {
        'base-button-filled': type === 'filled',
        'base-button-outlined': type === 'outlined',
        'base-button-link-button': type === 'link-button',
        'base-button-logout': type === 'logout',
        active,
      },
    ]"
    :disabled="disabled"
    @click="$emit('clicked')"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: "BaseButton",
  props: {
    type: {
      type: String,
      required: true,
      default: "filled",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
    },
    logout: {
      type: Boolean,
    },
  },
  emits: ["clicked"],
};
</script>
