import { createStore } from "vuex";

export default createStore({
  state: {
    user: localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null,
    isMobile: false,
    loading: false,
  },
  mutations: {
    MOBILE_CHECK(state, response) {
      state.isMobile = response;
    },
    SET_USER(state, response) {
      state.user = response;
    },
    LOADING(state, response) {
      state.loading = response;
    },
  },
  actions: {
    mobileCheck({ commit }, value) {
      commit("MOBILE_CHECK", value);
    },
    setUser({ commit }, value) {
      commit("SET_USER", value);
    },
    loading({ commit }, value) {
      document.body.style.overflow = value ? "hidden" : "";
      commit("LOADING", value);
    },
  },
  getters: {
    user: (s) => s.user,
    isMobile: (s) => s.isMobile,
    loading: (s) => s.loading,
  },
  modules: {},
});
