<template>
  <main class="main-layout">
    <!-- header -->
    <Header />
    <div class="main-layout-content">
      <!-- router-view -->
      <router-view />
    </div>
  </main>
</template>

<script>
import Header from "../header/Header.vue";

export default {
  name: "MainLAyout",
  components: {
    Header,
  },
};
</script>

<style lang="scss" scoped>
.main-layout {
  width: 100%;
  overflow: hidden;
  &-content {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
  }
}
</style>
