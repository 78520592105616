import { createApp } from "vue";
import router from "./router";
import App from "./App.vue";
import axios from "axios";
import store from "./store";
import VueAxios from "vue-axios";
import { createMetaManager } from "vue-meta";
import { axiosInstance } from "./plugins/axios";
import { mobileCheck } from "./helpers/mobileCheck";
// global styles
import "./assets/scss/style.scss";
import BaseButton from "@/components/common/Base.Button.vue";
import BaseLink from "@/components/common/Base.Link.vue";
import BaseInput from "@/components/common/Base.Input.vue";
import BaseModal from "@/components/common/Base.Modal.vue";

// Outside click event
const clickOutside = {
  beforeMount: (el, binding) => {
    el.clickOutsideEvent = (event) => {
      if (!(el == event.target || el.contains(event.target))) {
        binding.value();
      }
    };
    document.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted: (el) => {
    document.removeEventListener("click", el.clickOutsideEvent);
  },
};

// Outside click event
const tooltip = {
  mounted: (el) => {
    el.parentElement.offsetWidth - 30 > el.previousSibling.offsetWidth &&
      el.remove();
  },
};

// check mobile
mobileCheck(window.innerWidth);

const app = createApp(App);
app
  .directive("click-outside", clickOutside)
  .directive("tooltip", tooltip)
  .use(router)
  .use(VueAxios, axios)
  .use(store)
  .use(createMetaManager())
  .component("BaseButton", BaseButton)
  .component("BaseLink", BaseLink)
  .component("BaseInput", BaseInput)
  .component("BaseModal", BaseModal);
app.config.globalProperties.$isMobile = store.getters.isMobile;
app.config.globalProperties.$api = axiosInstance;
app.mount("#app");
