<template>
  <div
    class="base-modal"
    :class="{ 'base-modal--comfirm': comfirm }"
    v-if="modal"
  >
    <div class="base-modal-wrapper">
      <div
        class="base-modal-content"
        :class="{ open: showModal }"
        v-click-outside="close"
      >
        <div class="base-modal__close" @click="close">
          <img src="@/assets/icons/clear.svg" alt="Remove" />
        </div>
        <div class="base-modal-item">
          <slot name="content">
            <template v-if="!comfirm">
              <div class="base-modal__icon" :class="{ show: showModal }">
                <img src="@/assets/icons/check-mark-big.svg" alt="Accept" />
              </div>
            </template>
            <span class="base-modal__text"> {{ text }} </span>
            <template v-if="comfirm">
              <div class="base-modal__actions">
                <base-button :type="'outlined'" @clicked="accept">
                  Удалить
                </base-button>
                <base-button :type="'outlined'" @clicked="close">
                  Отмена
                </base-button>
              </div>
            </template>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watchEffect } from "vue";

export default {
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    comfirm: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "Отправлено",
    },
  },
  setup(props, { emit }) {
    let modal = ref(false);
    let showModal = ref(false);
    watchEffect(() => {
      if (props.open) {
        modal.value = true;
        document.body.style.overflow = "hidden";
        setTimeout(() => {
          showModal.value = true;
        }, 100);
      } else {
        showModal.value = false;
        setTimeout(() => {
          modal.value = false;
          document.body.style.overflow = "";
        }, 400);
      }
    });

    let accept = () => {
      emit("accept");
    };

    let close = () => {
      showModal.value && emit("close");
    };

    return {
      showModal,
      modal,
      accept,
      close,
    };
  },
  emits: ["close", "accept", "modalSubmit"],
};
</script>
