<template>
  <router-link :to="to" v-slot="{ href, navigate, isActive }" custom>
    <a
      :href="href"
      @click="navigate"
      class="base-link"
      :class="[
        {
          'base-link-active': isActive,
          'base-link-button': button,
          dropdown: dropdown,
        },
      ]"
      :style="{
        fontSize: `${size}px`,
        lineHeight: `${lineHeight}px`,
        fontWeight: weight,
        color: color,
      }"
    >
      <slot />
    </a>
  </router-link>
</template>

<script>
export default {
  name: "BaseLink",
  props: {
    to: {
      type: [String, Object],
      required: true,
    },
    family: {
      type: String,
    },
    size: {
      type: Number,
    },
    lineHeight: {
      type: Number,
    },
    weight: {
      type: Number,
    },
    color: {
      type: String,
    },
    align: {
      type: String,
    },
    button: {
      type: Boolean,
    },
    dropdown: {
      type: Boolean,
    },
  },
};
</script>
